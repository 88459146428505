<template>
  <nuxt />
</template>

<script>
export default {}
</script>

<style scoped>

</style>
