<template>
  <before-leave-popup
    :popup-component="popupComponent"
    title="Вы точно не хотите проверить наличие исполнительных производств?"
    submit-text="Проверить"
    cancel-text="Не хочу знать"
  >
    <div class="leave-popup">
      <div class="item">
        <div class="img-container">
          <img
            src="@/assets/img/before-leave/fssp.jpg"
            width="40"
            height="40"
          >
        </div>
        <div>
          Сервис проверит наличие исполнительных производств в Банке данных Федеральной службы судебных приставов
          (ФССП России).
        </div>
      </div>
    </div>
  </before-leave-popup>
</template>

<script>
export default {
  name: 'PopupBeforeLeaveFssp',
  components: {
    BeforeLeavePopup: () => import(/* webpackPrefetch: true */ '@pp-frontend/popups/component/BeforeLeavePopup.vue')
  },
  data () {
    return {
      popupComponent: () => import('~/components/popup/Popup.vue')
    }
  }
}
</script>

<style scoped>

</style>
