
import { Component } from 'nuxt-property-decorator'
import BeforeLeavePopup from '@pp-frontend/popups/component/BeforeLeavePopup.vue'

@Component
export default class PopupMobileBanner extends BeforeLeavePopup {
  component: any = () => import('~/components/popup/Popup.vue')

  google: string = 'https://play.google.com/store/apps/details?id=com.simplepayments.gosoplata'
  apple: string = 'https://apps.apple.com/ru/app/госоплата-штрафы-и-налоги/id1522915861'
}
