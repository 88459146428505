
import { Component, Vue } from 'nuxt-property-decorator'
import Features from '~/components/Features.vue'
import Container from '~/components/Container.vue'

@Component({
  components: {
    Container,
    Features
  }
})
export default class extends Vue {
}
