import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

export interface FormattersType {
  formatDate (value: string, time?: boolean): string
  formatAmount (value: string | number): string
  formatSnils (value: string): string
  formatCtc (value: string): string
  formatVu (value: string): string
  formatPassport (value: string): string
}

declare module '@nuxt/types' {
  interface Context {
    $formatters: FormattersType
  }

  interface NuxtAppOptions {
    $formatters: FormattersType
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $formatters: FormattersType
  }
}

export default function (_ctx: Context, inject: Inject) {
  /**
   * Форматирование даты из ISO в человеческий
   */
  function formatDate (value: string, time: boolean = false): string | void {
    if (value) {
      const date = new Date(value.replace(/-/g, '/'))
      const day: string = date.getDate().toString().padStart(2, '0')
      const month: string = (date.getMonth() + 1).toString().padStart(2, '0')

      return day + '.' + month + '.' + date.getFullYear() + (time ? ' ' + value.split(' ')[1].substr(0, 5) : '')
    }
  }

  /**
   * Перевод числа в формат 1 000 000,00
   */
  function formatAmount (value: string | number): string {
    if (typeof value === 'number') { value = value.toString() }
    if (value) {
      if (value.toString().includes('.')) {
        /** округляем до сотых */
        value = parseFloat(value).toFixed(2).toString()
      }

      let result: string = ''
      const isFloat: number = value.indexOf('.')
      if (isFloat > 0) {
        result = ',' + value.substring(isFloat + 1)
        value = value.slice(0, isFloat)
      }

      if (value.length - 1 < 3) {
        return value + result
      }

      let index: number = value.length
      while (index > 0) {
        /** добавляем непереносимый пробел ($nbsp;) */
        result = '\u00A0' + value.substring(index - 3, index) + result
        index = index - 3
      }

      return result.trim()
    }
    return value
  }

  /**
   * Форматируем СНИЛС в ###-###-### ##
   */
  function formatSnils (value: string):string {
    return value.substr(0, 3) + '-' + value.substr(3, 3) + '-' + value.substr(6, 3) + '\u00A0' + value.substr(9)
  }

  /**
   * Форматируем СТС в ## ## ######
   *
   * @param {string} value - СТС
   * @returns {string} formatCtc
   */
  function formatCtc (value: string) {
    return value.substr(0, 2) + '\u00A0' + value.substr(2, 2) + '\u00A0' + value.substr(4)
  }

  /**
   * Форматируем ВУ в ## ## ######
   *
   * @param {string} value - ВУ
   * @returns {string} formatVu
   */
  function formatVu (value: string) {
    return formatCtc(value)
  }

  /**
   * Форматируем паспорт в ## ## ######
   *
   * @param {string} value - паспорт
   * @returns {string} formatPassport
   */
  function formatPassport (value: string) {
    return formatCtc(value)
  }

  inject('formatters', {
    formatDate,
    formatAmount,
    formatSnils,
    formatCtc,
    formatVu,
    formatPassport
  })
}
