import Vue from 'vue'
import {Plugin} from '@nuxt/types'

interface EventBusType {
  $emit(eventName: string, data?: any): void

  $on(eventName: string, callback: Function): void

  $once(eventName: string, callback: Function): void

  $off(eventName: string, callback: Function): void
}

declare module '@nuxt/types' {
  interface Context {
    $eventBus2: EventBusType
  }

  interface NuxtAppOptions {
    $eventBus2: EventBusType
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $eventBus2: EventBusType
  }
}

const eventBus2: Plugin = (_ctx, inject) => {
  inject('eventBus2', new Vue())
}

export default eventBus2
