
import { Component, Vue } from 'nuxt-property-decorator'

@Component({})
export default class extends Vue {
  beforeMount () {
    (document.querySelector('body') as HTMLBodyElement).style.background = 'linear-gradient(180deg, rgba(255,231,215,1) 17%, rgba(255,249,244,1) 93%, rgba(255,255,255,1) 100%) no-repeat';
    (document.querySelector('body') as HTMLBodyElement).style.minHeight = '100vh !important'
  }
}
