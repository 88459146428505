import Vue from 'vue'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import '@pp-frontend/shared-packages/modules/shared-web-module/assets/floating-vue.scss'

Vue.use(FloatingVue, {
  themes: {
    'info-text': {
      $extend: 'dropdown',
      noAutoFocus: true
    },
    image: {
      $extend: 'dropdown',
      noAutoFocus: true
    },
    danger: {
      $extend: 'dropdown',
      noAutoFocus: true
    }
  }
})
