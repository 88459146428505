<template>
  <div>
    <web-push-popup
      ref="webPushPopup"
      :web-push-id="$webPush.getId()"
      :web-push-url="$webPush.getUrl()"
      :popup-component="webPush.popupComponent"
      :preloader-component="webPush.preloaderComponent"
      @open="handleWebPushShow"
      @closed="handleWebPushClose"
    />
    <template v-if="showIndexPopups">
      <popup-mobile-banner v-if="!mobileAppIsShown" @action="mobileAppIsShown = true" />
      <popup-before-leave-fssp v-else-if="$route.path === '/fssp'" />
      <popup-before-leave-gibdd v-else-if="$route.path === '/gibdd'" />
      <popup-before-leave-fns v-else-if="$route.path === '/fns' || $route.path === '/'" />
    </template>
  </div>
</template>

<script>
import WebPushPopup from '@pp-frontend/popups/component/WebPushPopup.vue'
import PopupMobileBanner from '~/components/popup/PopupMobileBanner.vue'
import PopupBeforeLeaveFssp from '~/components/popup/PopupBeforeLeaveFssp.vue'
import PopupBeforeLeaveGibdd from '~/components/popup/PopupBeforeLeaveGibdd.vue'
import PopupBeforeLeaveFns from '~/components/popup/PopupBeforeLeaveFns.vue'

export default {
  name: 'BeforeLeavePopups',
  components: {
    WebPushPopup,
    PopupBeforeLeaveFns,
    PopupBeforeLeaveGibdd,
    PopupBeforeLeaveFssp,
    PopupMobileBanner
  },
  data () {
    return {
      webPush: {
        isActive: false,
        hasShown: false,
        popupComponent: () => import('~/components/popup/Popup.vue'),
        preloaderComponent: () => import('~/components/Preloader.vue')
      },
      mobileAppIsShown: false
    }
  },
  computed: {
    routeIsIndex () {
      return !!this.$route.name && this.$route.name.includes('index') // index, index-fns, index-gibdd, index-fssp
    },
    showIndexPopups () {
      const webPushDisabled = !this.$webPush.allowed()
      const webPushAlreadyShown = this.webPush.hasShown
      const webPushIsNotShowing = !this.webPush.isActive

      return this.routeIsIndex && webPushIsNotShowing && (webPushAlreadyShown || webPushDisabled)
    }
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler () {
        if (!process.client) {
          return
        }

        if (this.routeIsIndex && !this.webPush.hasShown) {
          window.document.addEventListener('mouseleave', this.onMouseLeave)
        } else {
          window.document.removeEventListener('mouseleave', this.onMouseLeave)
        }
      }
    }
  },
  beforeDestroy () {
    window.document.removeEventListener('mouseleave', this.onMouseLeave)
  },
  methods: {
    handleWebPushShow () {
      this.webPush.isActive = true
    },
    handleWebPushClose () {
      this.webPush.isActive = false
      this.webPush.hasShown = true
    },
    onMouseLeave (e) {
      if (e.clientY <= 0 && this.$webPush.allowed()) {
        this.$refs.webPushPopup.openPopup()
        window.document.removeEventListener('mouseleave', this.onMouseLeave)
      }
    }
  }
}
</script>
