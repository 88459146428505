import Vue from 'vue'
import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

/** @type {IntersectionObserverInit} */
const observerOptions = {
  root: null,
  rootMargin: '300px',
  threshold: 0
}

/** загружаем изображение и проставляем соответствующие классы */
function loadElement (el, binding?) {
  if (!el) {
    return
  }

  if (binding?.arg === 'container') {
    el.classList.remove('isLoading')
    el.classList.add('isLoaded')

    if (binding.value) {
      el.style.backgroundImage = 'url(' + binding.value + ')'
    }

    return
  }

  el.addEventListener('load', () => {
    el.classList.remove('isLoading')
    el.classList.add('isLoaded')
  })

  el.addEventListener('error', () => {
    el.classList.remove('isLoading')
    el.classList.add('isError')
  })

  if (el.dataset.src) {
    (el as HTMLImageElement).src = el.dataset.src
    delete el.dataset.src
  }
}

/**
 * Обработчик для IntersectionObserver
 *
 * @param {IntersectionObserverEntry[]} entries - entries
 * @param {IntersectionObserver} observer - observer
 */
function handleIntersect (el, binding, entries, observer) {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      return
    }

    loadElement(el, binding)
    observer.unobserve(el)
  })
}

function createObserver (el, binding?) {
  el.classList.add('isLoading')

  /** @type {IntersectionObserver} */
  const observer = new IntersectionObserver(handleIntersect.bind(null, el, binding), observerOptions)
  observer.observe(el)
}

Vue.directive('lazy-load', {
  inserted (el, binding) {
    if ('IntersectionObserver' in window) {
      createObserver(el, binding)
    } else {
      loadElement(el, binding)
    }
  }
})

export default function (_ctx: Context, inject: Inject) {
  inject('lazyLoad', (el: HTMLElement) => {
    if ('IntersectionObserver' in window) {
      createObserver(el)
    } else {
      loadElement(el)
    }
  })
}
declare module '@nuxt/types' {
  interface Context {
    $lazyLoad(el: HTMLElement): void
  }

  interface NuxtAppOptions {
    $lazyLoad(el: HTMLElement): void
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $lazyLoad(el: HTMLElement): void
  }
}
