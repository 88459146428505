import { Severity } from '@sentry/types'

export const state = () => ({
  questions: []
})

export const getters = {
  getList: (state) => {
    return state.questions
  }
}

export const mutations = {
  setList (state, questions) {
    state.questions.splice(0, state.questions.length, ...questions)
  }
}

export const actions = {
  setList ({ commit }) {
    return this.$static.getPageList('pages', 3)
      .then((response) => {
        commit('setList', Object.freeze(response))
      }).catch(() => {
        this.$sentry.captureMessage('Ошибка при получении списка статей', Severity.Warning)
      })
  }
}
