// eslint-disable-next-line @typescript-eslint/no-unused-vars, jsdoc/require-jsdoc
export default function (to, from, savedPosition) {
  if (to.hash) {
    return {
      selector: to.hash,
      behavior: 'smooth'
    }
  } else {
    return { x: 0, y: 0 }
  }
}
