<template>
  <before-leave-popup
    :popup-component="popupComponent"
    title="Вы точно не хотите проверить наличие просроченных налоговых задолженностей?"
    submit-text="Проверить"
    cancel-text="Не хочу знать"
  >
    <div class="leave-popup">
      <div class="item">
        <div class="img-container">
          <img
            src="@/assets/img/before-leave/fns.jpg"
            width="40"
            height="40"
          >
        </div>
        <div>
          Налоговая инспекция могла не получить сведения о смене статуса Вашего имущества или не вовремя получить
          сведения об оплате налогов и начислить пени.
          Наш сервис проверит наличие просроченных налоговых задолженностей в базе данных ФНС России и покажет Вам их
          текущее состояние.
        </div>
      </div>
    </div>
  </before-leave-popup>
</template>

<script>
export default {
  name: 'PopupBeforeLeaveFns',
  components: {
    BeforeLeavePopup: () => import(/* webpackPrefetch: true */ '@pp-frontend/popups/component/BeforeLeavePopup.vue')
  },
  data () {
    return {
      popupComponent: () => import('~/components/popup/Popup.vue')
    }
  }
}
</script>

<style scoped>

</style>
