import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { YMGoals } from '@pp-frontend/shared-packages/modules/analytics/plugins/y-metrics.client'
import { PaymentCard } from '@pp-frontend/shared-packages/types/PaymentCard'

export interface AutoPaymentPlugin {
  // метод для получения информации по заказу - документ для авто-оплаты и карта, которая привязывается
  fetchInfoByOrderId: (order_id: string | undefined) => Promise<{ card: PaymentCard, document: any } | null>
  // метод включения авто-оплаты
  connectCardToDocument: (card_id: string | number, document_id: string | number) => Promise<boolean>
  // Отключение авто-оплаты - убирает все привязанные карты у документа
  cancelAutoPayment: (document_id: string | number) => Promise<boolean>
}

/**
 * @param {Context} ctx - Nuxt context
 * @param {Inject} inject - inject plugin fn
 */
export default function (ctx: Context, inject: Inject) {
  const fetchInfoByOrderId = async (
    order_id: string
  ): Promise<{ card: PaymentCard, document: any } | null> => {
    if (!order_id) {
      throw new Error('Не передан номер заказа')
    }
    try {
      // order_id = '5869614' // test order_id
      const { data } = await ctx.$axios.get(process.env.API_ENDPOINT + `user/order/${order_id}`)
      return data
    } catch (err) {
      ctx.$sentry.captureException(err)
      console.error(err)
      return null
    }
  }

  const connectCardToDocument = async (
    card_id: string | number,
    document_id: string | number
  ): Promise<boolean> => {
    try {
      ctx.$yandexMetrics.reachGoal(YMGoals.AUTOPAYMENT_CONNECT_CLICK)
      await ctx.$axios.post(process.env.API_ENDPOINT + `recurrent/${card_id}/auto-payment-activate`, { document_id })
      ctx.$yandexMetrics.reachGoal(YMGoals.AUTOPAYMENT_CONNECT_SUCCESS)
      return true
    } catch (err) {
      ctx.$sentry.captureException(err)
      console.error(err)
      return false
    }
  }

  const cancelAutoPayment = async (
    document_id: string | number
  ): Promise<boolean> => {
    try {
      await ctx.$axios.get(process.env.API_ENDPOINT + `recurrent/${document_id}/auto-payment-disable`)
      return true
    } catch (err) {
      ctx.$sentry.captureException(err)
      console.error(err)
      return false
    }
  }

  inject('autoPayment', {
    fetchInfoByOrderId,
    connectCardToDocument,
    cancelAutoPayment
  })
}
