import {Context} from '@nuxt/types'
import {ActivityStorage} from '@pp-frontend/shared-packages/types/features/ActivityStorage'
import LocalStorageFeature from '@pp-frontend/shared-packages/classes/feature/LocalStorageFeature'
import {Inject} from '@nuxt/types/app'

/**
 * @param {Context} ctx - nuxt контекст
 * @param {Inject} inject - функция добавления плагина
 */
export default function (_ctx: Context, inject: Inject) {
  const LAST_ACTIVITY_STORAGE: string = 'lastActivity'

  inject('lastActivity', new LocalStorageFeature<ActivityStorage>(LAST_ACTIVITY_STORAGE))
}
declare module '@nuxt/types' {
  interface Context {
    $lastActivity: LocalStorageFeature<ActivityStorage>
  }

  interface NuxtAppOptions {
    $lastActivity: LocalStorageFeature<ActivityStorage>
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $lastActivity: LocalStorageFeature<ActivityStorage>
  }
}
