import {Context} from '@nuxt/types'
import {Inject} from '@nuxt/types/app'
import LocalStorageFeature from '@pp-frontend/shared-packages/classes/feature/LocalStorageFeature'
import {Payer} from '@pp-frontend/shared-packages/types/Payer'

declare module '@nuxt/types' {
    interface Context {
        $lastPayment: LocalStorageFeature<Payer>
    }

    interface NuxtAppOptions {
        $lastPayment: LocalStorageFeature<Payer>
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $lastPayment: LocalStorageFeature<Payer>
    }
}

export default function (_ctx: Context, inject: Inject) {
    const LAST_PAYMENT_STORAGE: string = 'lastPayment'

    inject('lastPayment', new LocalStorageFeature<Payer>(LAST_PAYMENT_STORAGE))
}
