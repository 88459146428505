import {Context} from "@nuxt/types/app"
import LRUCache from 'lru-cache'
import {cacheAdapterEnhancer, ICacheLike} from 'axios-extensions'

declare module 'axios' {
  export interface AxiosRequestConfig {
    cache?: boolean | ICacheLike<any>
    requireToken?: boolean
  }
}

/**
 * Расширение axios
 * Добавляем возможность кеширования запросов на 1 час
 * Добавляет версию приложения для тестирования разных вариантов веб сервисов
 */
export default function (
  ctx: Context
) {
  const ONE_HOUR = 1000 * 60 * 60
  const defaultCache = new LRUCache({maxAge: ONE_HOUR})
  // Кеширование запросов
  // используется только для статики - можно вынести в отдельную апишку для статики
  // @ts-ignore
  ctx.$axios.defaults.adapter = cacheAdapterEnhancer(ctx.$axios.defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: 'cache',
    defaultCache
  })

  ctx.$axios.setHeader('App-Version', ctx.$config.APP_VERSION)
}
