import {Context} from '@nuxt/types'
import {Inject} from '@nuxt/types/app'

export interface AllowServices {
  inn_by_passport: boolean
  esia_auth: boolean
  ctc_by_grz: boolean
  fns_transport_tax_calculator: boolean
}

declare module '@nuxt/types' {
  interface Context {
    $allowServices: () => AllowServices
  }

  interface NuxtAppOptions {
    $allowServices: () => AllowServices
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $allowServices: () => AllowServices
  }
}

export default function (ctx: Context, inject: Inject) {
  let allowServices = {
    inn_by_passport: true,
    esia_auth: true,
    ctc_by_grz: true,
    fns_transport_tax_calculator: true
  }

  if (process.client) {
    ctx.$axios.get(process.env.API_ENDPOINT + 'user/allow-services').then((response) => {
      allowServices = Object.assign(allowServices, response.data)
    }).catch(() => {
    })
  }

  inject('allowServices', () => allowServices)
}
