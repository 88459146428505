
export default {
  props: {
    type: {
      type: String,
      default: 'gibdd'
    },
    texts: {
      type: Object,
      default: () => ({})
    }
  }
}
