import {Context} from '@nuxt/types'
import {Inject} from '@nuxt/types/app'
import {OnDemand} from '@pp-frontend/shared-packages/utils';

interface IYaMaps {
  addMap(id: string, points: string[]): void
}

declare module '@nuxt/types' {
  interface Context {
    $yaMaps: IYaMaps
  }

  interface NuxtAppOptions {
    $yaMaps: IYaMaps
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $yaMaps: IYaMaps
  }
}

export default (_ctx: Context, inject: Inject) => {
  const loader = new OnDemand('https://api-maps.yandex.ru/2.1/?load=package.standard&lang=ru_RU&apikey=95f507ed-432d-48d3-9b8f-d389f532b9c8')

  function init(elementId: string, address: string[]) {
    var myMap: any = null
    try {
      // @ts-ignore
      myMap = new ymaps.Map(elementId, {
        center: [50, 50],
        controls: ['zoomControl', 'fullscreenControl'],
        zoom: 15,
        behaviors: ['drag']
      }, {
        suppressMapOpenBlock: true
      })

      myMap.controls.add('zoomControl')
    } catch (err) {
      console.log(err)
    }

    if (myMap) {
      let placemark_index = 0;

      function setCenter() {
        placemark_index += 1;
        if (address.length === placemark_index) {
          myMap.setBounds(myMap.geoObjects.getBounds(), {checkZoomRange: true}).then(function () {
            if (myMap.getZoom() > 15) myMap.setZoom(15);
          });
        }
      }

      address.forEach(function (address) {
        // @ts-ignore
        ymaps.geocode(address).then(function (res) {
          let coord = res.geoObjects.get(0).geometry.getCoordinates();
          // @ts-ignore
          let myPlacemark = new ymaps.Placemark(coord, {}, {
            preset: 'islands#blueDotIcon'
          });

          // myMap.geoObjects.add(firstGeoObject);
          // myMap.setBounds(bounds, {
          //   checkZoomRange: true
          // })
          myMap.geoObjects.add(myPlacemark);
          setCenter();
        });
      })

      let timeout: any = null

      window.onscroll = function () {
        if (timeout) {
          clearTimeout(timeout)
        }

        if (myMap.behaviors.isEnabled('drag')) {
          myMap.behaviors.disable('drag')
        }

        timeout = setTimeout(function () {
          if (!myMap.behaviors.isEnabled('drag')) {
            myMap.behaviors.enable('drag')
          }
        }, 500)
      }
    }
  }

  inject('yaMaps', {
    addMap(id: string, points: string[]) {
      loader.load(() => {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        ymaps.ready(() => {
          // @ts-ignore
          // eslint-disable-next-line no-undef
          init(id, points)
        })
      })
    }
  })
}
