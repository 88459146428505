
import { Component, Provide, Vue } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import Header from '~/components/Header.vue'
import Footer from '~/components/Footer.vue'
import BeforeLeavePopups from '~/components/popup/BeforeLeavePopups.vue'

@Component({
  components: {
  LazyHydrate,
  Header,
  Footer,
  Notify: () => import('@pp-frontend/authorization/component/Notify.vue'),
  BeforeLeavePopups
  }
  })
export default class extends Vue {
  @Provide('excludeCacheFunction') clearPageCache: (name: string) => void = (name: string) => {
    this.keepAliveExclude.push(name)

    this.$nextTick(() => {
      this.keepAliveExclude.pop()
    })
  }

  keepAliveExclude: string[] = ['payment', 'payment-fail', 'subscribe']
  notifyIsOpen: boolean = false

  beforeMount () {
    this.$ga.updateStorage()
  }

  mounted () {
    this.initInAppMessageHandler()
  }

  scrollToTopPage (path: string) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // @ts-ignore
    this.$router.push(path)
  }

  initInAppMessageHandler () {
    if (this.$fireModule.messaging.isSupported()) {
      this.$fire.messaging.onMessage((e) => {
        const isConfirm = confirm(e.notification.body + ' Посмотреть?')

        if (isConfirm) {
          window.location.href = e.notification.click_action
        }
      })
    }
  }
}
