
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  components: {
  Notify: () => import('@pp-frontend/authorization/component/Notify.vue')
  }
  })
export default class extends Vue {
  notifyIsOpen: boolean = false
}
