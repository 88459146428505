import Vue from 'vue'
import {Context} from '@nuxt/types'
import {Inject} from '@nuxt/types/app'

interface EventBusType {
  $emit(eventName: string, data?: any): void

  $on(eventName: string, callback: Function): void

  $once(eventName: string, callback: Function): void

  $off(eventName: string, callback: Function): void
}

declare module '@nuxt/types' {
  interface Context {
    $eventBus: EventBusType
  }

  interface NuxtAppOptions {
    $eventBus: EventBusType
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $eventBus: EventBusType
  }
}

class EventBus extends Vue {
  private getEventName(event) {
    let route = ''
    if (process.client) {
      route = window.location.href
    }

    return event + route
  }

  // @ts-ignore
  $emit(event: string, ...args: any[]): this {
    // @ts-ignore
    return super.$emit(this.getEventName(event), ...args)
  }

  $on(event: string | string[], callback: Function): this {
    return super.$on(this.getEventName(event), callback)
  }

  $once(event: string | string[], callback: Function): this {
    return super.$once(this.getEventName(event), callback)
  }

  $off(event?: string | string[], callback?: Function): this {
    return super.$off(this.getEventName(event), callback)
  }
}

/**
 * @param {Context} _ctx - nuxt контекст
 * @param {Inject} inject - функция добавления плагина
 */
export default function (_ctx: Context, inject: Inject) {
  inject('eventBus', new EventBus())
}
