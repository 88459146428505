
import { Component, Vue } from 'nuxt-property-decorator'

interface Feature {
  img: string
  title: string
  text: string
}

@Component
export default class extends Vue {
  items: Feature[] = [
    {
      img: require('~/assets/img/features/icon__shield.svg'),
      title: 'Гарантия погашения',
      text: 'Сведения об оплате автоматически передаются в базы данных ГИБДД, ФНС и ФССП через шлюз Федерального Казначейства'
    },
    {
      img: require('~/assets/img/features/icon__gov.svg'),
      title: 'Официальные данные',
      text: 'Поиск задолженностей проводится в базах данных Федерального казначейства, ФССП России, ГИБДД и ФНС'
    },
    {
      img: require('~/assets/img/features/icon__payment.svg'),
      title: 'Мгновенная оплата',
      text: 'Платежное поручение кредитной организации бесплатно отправляется Вам на адрес электронной почты сразу же по совершению оплаты'
    },
    {
      img: require('~/assets/img/features/icon__pci.svg'),
      title: 'Безопасные платежи',
      text: 'Безопасность платежей обеспечивается сквозным TLS шифрованием и подтверждена сертификатом PCI DSS'
    }
  ]
}
