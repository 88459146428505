import Vue from 'vue'
import {Context} from '@nuxt/types'
import {Inject} from '@nuxt/types/app'

declare module '@nuxt/types' {
    interface Context {
        $pluralEnds(number: number, titles: string[]): string

        $pluralEnds2(count: number, declensions: string[], values?: { [key: string]: string }): string
    }

    interface NuxtAppOptions {
        $pluralEnds(number: number, titles: string[]): string

        $pluralEnds2(count: number, declensions: string[], values?: { [key: string]: string }): string
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $pluralEnds(number: number, titles: string[]): string

        $pluralEnds2(count: number, declensions: string[], values?: { [key: string]: string }): string
    }
}

/**
 * @param {Context} _ctx - nuxt контекст
 * @param {Inject} inject - функция добавления плагина
 */
export default function (_ctx: Context, inject: Inject) {
    /**
     * Подбирает окончания
     *
     * @param {number} number - число, для которого нужно склонять строку
     * @param {string[]} titles - массив слов, которые нужно склонять
     * @returns {string} result
     */
    function pluralEnds(number: number, titles: string[]): string {
        const cases = [2, 0, 1, 1, 1, 2]
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
    }

    /**
     * Улучшенная версия для склонения строк
     *
     * @param {number} count - число - вариант склонения
     * @param {string[]} declensions - массив строк, которые нужно склонять
     * @param {[key: string]: string | number} values - массив строк, которые нужно склонять
     *
     * Если в строке будет присутствовать {n} - count подставится на его место
     * Пример
     * let accrualsCount = 10
     * $pluralEnds2(accrualsCount, ['найден {n} штраф', 'найдено {n} штрафа', 'найдено {n} штрафов']) // найдено 10 штрафов
     *
     * Если в строке будет ключ {key} и передано values содержит ключ key - key подставится на его место
     * Пример
     * let accrualsCount = 10
     * $pluralEnds2(accrualsCount, [
     * 'найден {accrualsCount} штраф', // 1
     * 'найдено {accrualsCount} штрафа', // 2-4
     * 'найдено {accrualsCount} штрафов' // 5+
     * ],{accrualsCount})
     * // найдено 10 штрафов
     *
     * @returns {string} result
     */
    function pluralEnds2(
        count: number,
        declensions: [string, string, string],
        values: { [key: string]: string | number } = {}
    ): string {
        const wordIndex =
            count % 10 === 1 && count % 100 !== 11
                ? 0
                : count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)
                    ? 1
                    : 2;
        let result = declensions[wordIndex];
        result = result.replace(`{n}`, String(count))
        for (const key in values) {
            result = result.replace(`{${key}}`, String(values[key]))
        }
        return `${result}`;
    }

    inject('pluralEnds', pluralEnds)
    inject('pluralEnds2', pluralEnds2)
}
