import {Context} from "@nuxt/types";
import {Inject} from "@nuxt/types/app";

export interface EventPayload {
    event_action: string
    [param: string]: string
}

export interface BackendAnalytics {
    sendEvent(payload: EventPayload | string): void
}

declare module '@nuxt/types' {
    interface Context {
        $analytics: BackendAnalytics
    }

    interface NuxtAppOptions {
        $analytics: BackendAnalytics
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $analytics: BackendAnalytics
    }
}

export default function ({$api}: Context, inject: Inject) {
    function sendEvent(payload: EventPayload | string) {
        if (typeof payload === 'string') {
            $api.get('user/event', {params: { event_action: payload }})
        } else {
            $api.get('user/event', {params: payload})
        }
        if (process.env.DEV_MODE === 'true') {
            console.log('analytics event sent', payload)
        }
    }

    inject('analytics', {
        sendEvent
    })
}
