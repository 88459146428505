import {Context, Inject} from "@nuxt/types/app"
import {NuxtAxiosInstance} from "@nuxtjs/axios"

declare module '@nuxt/types' {
    interface Context {
        $api: NuxtAxiosInstance
    }

    interface NuxtAppOptions {
        $api: NuxtAxiosInstance
    }
}
declare module 'vue/types/vue' {
    interface Vue {
        $api: NuxtAxiosInstance
    }
}

/**
 * Axios Instance для простых платежей
 *
 * Ходит на https://api.xn--80ajbekothchmme5j.xn--p1ai/ [По русски - https://api.простыеплатежи.рф]
 */
export default function (ctx: Context, inject: Inject) {
    const api = ctx.$axios.create({
        baseURL: ctx.$config.API_URL || 'https://api.xn--80ajbekothchmme5j.xn--p1ai/',
        withCredentials: true,
        timeout: 10_000
    })

    ctx.$api = api
    inject('api', api)
}
