
export default {
  methods: {
    scrollToElement (elementId) {
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },
    scrollToTopPage (path: string) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      // @ts-ignore
      this.$router.push(path)
    }

  }
}
